import Axios, { AxiosError, AxiosRequestConfig } from 'axios'
import * as process from 'process'
import qs from 'qs'

export const AXIOS_INSTANCE = Axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL || '',
  paramsSerializer(params) {
    return qs.stringify(params, { indices: false })
  }
})

export const fetcher = <T>(config: AxiosRequestConfig): Promise<T> => {
  const source = Axios.CancelToken.source()
  const promise = AXIOS_INSTANCE<T>({
    ...config,
    cancelToken: source.token
  }).then(({ data }) => data)

  AXIOS_INSTANCE.interceptors.request.use((value) => {
    if (window) {
      value.headers['X-Requested-From'] = window.location.href
    }
    return value
  })
  AXIOS_INSTANCE.interceptors.response.use(undefined, errorResponse)
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled')
  }

  return promise
}

export const errorResponse = async (error: any) => {
  if (error.response?.status === 401 && !error.config._retry) {
    error.config._retry = true
    const { data } = await Axios.get('/api/auth/session', { withCredentials: true })
    const token = data.id_token
    AXIOS_INSTANCE.defaults.headers.common.Authorization = `Bearer ${token}`
    error.config.headers.Authorization = `Bearer ${token}`
    return AXIOS_INSTANCE.request(error.config)
  }
  throw error
}

export type ErrorType<Error> = AxiosError<Error>

export type BodyType<BodyData> = BodyData
