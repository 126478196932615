'use client'
import { Button, Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from './ui/'
import { useSession } from 'next-auth/react'
import { UserCheck } from 'lucide-react'
import { fetcher } from '@zent-auth/lib/fetcher'

const RefreshMyInfoButton = () => {
  const { update } = useSession()

  const handleClick = () => {
    fetcher({ url: `/users/zent/me`, method: 'GET' })
      .then((user) => update({ user }))
      .then(() => window.location.reload())
  }

  return (
    <TooltipProvider delayDuration={100}>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button variant="outline" size="icon" onClick={handleClick}>
            <UserCheck className="w-4 h-4" />
          </Button>
        </TooltipTrigger>
        <TooltipContent>내 권한 리로드</TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}

export default RefreshMyInfoButton
