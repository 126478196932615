import React, { FC, PropsWithChildren } from 'react'
import { cva, type VariantProps } from 'class-variance-authority'
import { cn } from '../utils'

const buttonVariants = cva('flex flex-wrap gap-2', {
  variants: {
    place: {
      start: 'place-items-start',
      center: 'place-items-center',
      end: 'place-items-end',
      stretch: 'place-items-stretch'
    },
    justify: {
      normal: 'justify-normal',
      start: 'justify-start',
      end: 'justify-end',
      center: 'justify-center',
      betweem: 'justify-betweem',
      around: 'justify-around',
      evenly: 'justify-evenly',
      stretch: 'justify-stretch'
    }
  },
  defaultVariants: {
    place: 'center',
    justify: 'normal'
  }
})

export type ButtonsProps = PropsWithChildren<React.BaseHTMLAttributes<HTMLDivElement>> &
  VariantProps<typeof buttonVariants>

const Buttons: FC<ButtonsProps> = ({ className, place, justify, children, ...rest }) => {
  return (
    <div className={cn(buttonVariants({ className, place, justify }))} {...rest}>
      {children}
    </div>
  )
}

export default Buttons
