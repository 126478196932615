'use client'
import React, { FC, PropsWithChildren, useEffect, useState } from 'react'
import { useSession } from 'next-auth/react'
import { usePathname } from 'next/navigation'
import { useCookies } from 'react-cookie'
import { AXIOS_INSTANCE } from '@zent-auth/lib/fetcher'
import ConsoleBreadcrumb from './ConsoleBreadcrumb'
import ModeToggle from './ModeToggle'
import RefreshMyInfoButton from './RefreshMyInfoButton'
import Buttons from './Buttons'
import { SidebarProvider, SidebarTrigger } from './ui/sidebar'
import RootSidebar from './RootSidebar'

const RootWrapper: FC<PropsWithChildren> = ({ children }) => {
  const { data: session } = useSession()

  const [cookies, setCookie] = useCookies(['zent-console-menubar-open'])

  const pathname = usePathname()
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>()

  const getMenuOpen = () => {
    setIsMenuOpen(cookies['zent-console-menubar-open'] !== 'close')
  }

  useEffect(() => {
    setCookie('zent-console-menubar-open', isMenuOpen ? 'open' : 'close')
  }, [isMenuOpen])

  useEffect(() => {
    getMenuOpen()
  }, [])

  if (!session) {
    if (pathname !== '/') {
      document.location.href = '/'
    }
  } else {
    if (!AXIOS_INSTANCE.defaults.headers.common.Authorization) {
      AXIOS_INSTANCE.defaults.headers.common.Authorization = `Bearer ${session.id_token}`
    }
  }

  return (
    <div className="overflow-x-hidden w-full pr-16 pl-16">
      <div className="">
        <SidebarProvider>
          <RootSidebar />
          <div className="overflow-x-hidden w-full pr-16 pl-16">
            <SidebarTrigger />
            <div className="flex items-center justify-between w-full">
              <ConsoleBreadcrumb />
              <Buttons>
                <RefreshMyInfoButton />
                <ModeToggle />
              </Buttons>
            </div>
            {children}
          </div>
        </SidebarProvider>
      </div>
    </div>
  )
}
export default RootWrapper
